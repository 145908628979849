import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "list-unstyled" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_infinite_scroll_collection = _resolveDirective("infinite-scroll-collection")!

  return _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pitchMoments, (pitchMoment) => {
      return (_openBlock(), _createElementBlock("li", {
        key: `pitch-moment-${pitchMoment.id}`,
        class: "mb-4"
      }, [
        _renderSlot(_ctx.$slots, "default", {
          isUpcoming: _ctx.upcomingPitchMoment && _ctx.upcomingPitchMoment.id === pitchMoment.id,
          pitchMoment: pitchMoment
        })
      ]))
    }), 128))
  ])), [
    [
      _directive_infinite_scroll_collection,
      {collection: _ctx.pitchMoments, options: _ctx.options},
      void 0,
      { document: true }
    ]
  ])
}