import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createBlock(_component_FormGroup, {
    "no-prepend-style": "",
    placeholder: _ctx.$t('actions.search')
  }, {
    "group-prepend": _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_Fa, {
          class: "text-muted",
          icon: "search",
          size: "sm"
        })
      ])
    ]),
    _: 1
  }, 8, ["placeholder"]))
}