import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center h-100" }
const _hoisted_2 = { class: "me-auto" }
const _hoisted_3 = { "data-test-id": "pitches-table-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "d-flex flex-column justify-content-center align-items-center h-100"
}
const _hoisted_5 = { class: "mb-4" }
const _hoisted_6 = { class: "fs-5 mb-6 max-w-400px text-center" }
const _hoisted_7 = {
  class: "btn btn-primary",
  "data-bs-target": "#pitch-setup",
  "data-bs-toggle": "modal"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionFilter = _resolveComponent("CollectionFilter")!
  const _component_BtnCreateModal = _resolveComponent("BtnCreateModal")!
  const _component_Portal = _resolveComponent("Portal")!
  const _component_PitchesTable = _resolveComponent("PitchesTable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Portal, { to: "navbar-top-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t(`pitch.filters.${_ctx.activeFilter}`)), 1),
          _createVNode(_component_CollectionFilter, {
            collection: _ctx.pitches,
            "filter-attribute": "name",
            size: "sm",
            transparent: ""
          }, null, 8, ["collection"]),
          (!_ctx.teamsIsEmpty)
            ? (_openBlock(), _createBlock(_component_BtnCreateModal, {
                key: 0,
                class: "h-100 ms-4",
                pitches: _ctx.pitches
              }, null, 8, ["pitches"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _withDirectives(_createElementVNode("div", _hoisted_3, [
      _createVNode(_component_PitchesTable, {
        headers: _ctx.headers,
        pitches: _ctx.pitches
      }, null, 8, ["headers", "pitches"])
    ], 512), [
      [_vShow, _ctx.teamsIsEmpty || !_ctx.noPitches]
    ]),
    (!_ctx.teamsIsEmpty && _ctx.noPitches)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('pitch.index.createPitchTitle')), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('pitch.index.createPitchBody')), 1),
          _createElementVNode("button", _hoisted_7, _toDisplayString(_ctx.$t('pitch.actions.createFirstPitch')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}