
import {Component, defineComponent} from 'vue';
import Home from '@/views/Home.vue';
import OrganisationsIndex from '@/views/organisations/Index.vue';
import TeamType from '@/library/enumerations/TeamType';

export default defineComponent({
    components: {
        Home,
        OrganisationsIndex,
    },
    props: {
        teamType: {
            type: Number,
            required: true,
            validator: (value: number) => TeamType.values.includes(value),
        },
    },
    computed: {
        dashboardComponent(): Component {
            switch (this.teamType) {
                case TeamType.ORGANISATION:
                    return OrganisationsIndex;
                default:
                    return Home;
            }
        },
    },
});
