
import {defineComponent, ref} from 'vue';
import BtnCreateModal from '@/components/pitchMoments/BtnCreateModal.vue';
import {PitchMoments} from '@/models/PitchMoment';
import PitchMomentsList from '@/components/pitchMoments/List.vue';
import PitchesCollapse from '@/components/pitchMoments/PitchesCollapse.vue';
import {Portal} from 'portal-vue';
import teamStore from '@/store/userTeams';

export default defineComponent({
    components: {
        BtnCreateModal,
        PitchMomentsList,
        PitchesCollapse,
        Portal,
    },
    setup() {
        const {teamProgram} = teamStore;

        return {
            teamProgram,
            pitchMoments: ref<PitchMoments>(new PitchMoments([], {
                include: ['pitches'],
                pitchTeam: teamStore.currentTeam.value?.id,
                sorts: 'deadlineAt',
            })),
        };
    },
    computed: {
        noPitchMoments(): boolean {
            return this.pitchMoments.isEmpty()
                && !this.pitchMoments.loading;
        },
        listOptions(): Record<string, any> {
            return {
                url: `/teams/${this.teamProgram.id}/pitch-moments`,
            };
        },
    },
});
