
import FormGroup from '@/components/common/form/FormGroup.vue';
import {defineComponent} from 'vue';

/**
 * Creates a search input field.
 * Makes use of the Form Group component and appends a search icon to it.
 * Search icon does not hold any functionality, it is only for design.
 */
export default defineComponent({
    components: {
        FormGroup,
    },
});
