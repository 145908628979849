import { toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateModal = _resolveComponent("CreateModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", _mergeProps(_ctx.$attrs, {
      class: "btn btn-primary btn-sm",
      "data-bs-target": "#pitch-setup",
      "data-bs-toggle": "modal"
    }), _toDisplayString(_ctx.$t('pitch.actions.newPitch')), 17),
    (_ctx.currentTeam)
      ? (_openBlock(), _createBlock(_component_CreateModal, {
          key: 0,
          pitches: _ctx.pitches,
          "team-id": _ctx.currentTeam.id
        }, null, 8, ["pitches", "team-id"]))
      : _createCommentVNode("", true)
  ], 64))
}