
import {PropType, defineComponent, ref} from 'vue';
import Collection from '@/models/Collection';
import SearchField from '@/components/common/form/SearchField.vue';
import debounce from 'lodash/debounce';
import {get} from 'lodash';

/**
 * Uses the Search Field component.
 * Links the search input to the filter attribute of a collection.
 * The filter value is saved locally but the collection is only updated once
 * every 250ms for a collection is likely to re-fetch after the filter has
 * been changed.
 */
export default defineComponent({
    components: {
        SearchField,
    },
    props: {
        collection: {
            type: Object as PropType<Collection<Record<string, any>, Record<string, any>>>,
            required: true,
        },
        filterAttribute: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const value = get(
            props.collection,
            `filters.${props.filterAttribute}`,
            '',
        );

        return {
            filterValue: ref<string>(value),
        };
    },
    created() {
        this.setCollectionFilter = debounce(this.setCollectionFilter, 250);
    },
    beforeUnmount() {
        this.filterValue = '';

        this.updateCollectionFilter();
    },
    methods: {
        updateFilterValue(value: string) {
            this.filterValue = value;

            this.setCollectionFilter();
        },
        setCollectionFilter() {
            this.updateCollectionFilter();
        },
        updateCollectionFilter() {
            if (this.filterValue) {
                this.collection.addFilter(this.filterAttribute, this.filterValue);
            } else {
                this.collection.removeFilter(this.filterAttribute);
            }
        },
    },
});
