
import PitchMoment, {PitchMoments} from '@/models/PitchMoment';
import {PropType, defineComponent} from 'vue';
import {fetchCollectionUntilScrollable, firstScrollingParent} from '@/library/helpers';
import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';

export default defineComponent({
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
        pitchMoments: {
            type: Object as PropType<PitchMoments>,
            required: true,
        },
    },
    computed: {
        upcomingPitchMoment(): PitchMoment | undefined {
            return this.pitchMoments.first((moment: PitchMoment) => {
                const deadlineDate = parseISO(moment.deadlineAt);

                return isFuture(deadlineDate);
            });
        },
    },
    mounted() {
        if (this.pitchMoments.isEmpty()) {
            this.fetchCollection();
        }
    },
    methods: {
        fetchCollection() {
            this.pitchMoments.clear();

            fetchCollectionUntilScrollable(
                this.pitchMoments,
                firstScrollingParent(this.$el),
                this.options,
            );
        },
    },
});
