import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center h-100" }
const _hoisted_2 = { class: "me-auto" }
const _hoisted_3 = {
  key: 0,
  class: "h-100 d-flex flex-column align-items-center justify-content-center text-center"
}
const _hoisted_4 = { class: "text-muted mt-4 max-w-400px" }
const _hoisted_5 = {
  key: 1,
  class: "mt-6 text-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BtnCreateModal = _resolveComponent("BtnCreateModal")!
  const _component_Portal = _resolveComponent("Portal")!
  const _component_Fa = _resolveComponent("Fa")!
  const _component_PitchesCollapse = _resolveComponent("PitchesCollapse")!
  const _component_PitchMomentsList = _resolveComponent("PitchMomentsList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Portal, { to: "navbar-top-content" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('organisations.index.title')), 1),
          (!_ctx.pitchMoments.isEmpty())
            ? (_openBlock(), _createBlock(_component_BtnCreateModal, { key: 0 }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    (_ctx.noPitchMoments)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('organisations.index.noPitchMomentsTitle')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('organisations.index.noPitchMomentsBody')), 1),
          (_ctx.teamProgram.id)
            ? (_openBlock(), _createBlock(_component_BtnCreateModal, {
                key: 0,
                class: "mt-6"
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_Fa, { icon: "exclamation-triangle" }),
                _createTextVNode(" " + _toDisplayString(_ctx.$t('organisations.index.noProgramError')), 1)
              ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.teamProgram.id)
      ? _withDirectives((_openBlock(), _createBlock(_component_PitchMomentsList, {
          key: 1,
          options: _ctx.listOptions,
          "pitch-moments": _ctx.pitchMoments
        }, {
          default: _withCtx(({isUpcoming, pitchMoment}) => [
            _createVNode(_component_PitchesCollapse, {
              class: "bg-white rounded",
              open: isUpcoming,
              "pitch-moment": pitchMoment
            }, null, 8, ["open", "pitch-moment"])
          ]),
          _: 1
        }, 8, ["options", "pitch-moments"])), [
          [_vShow, !_ctx.noPitchMoments]
        ])
      : _createCommentVNode("", true)
  ]))
}