
import BtnCreateModal from '@/components/pitches/BtnCreateModal.vue';
import CollectionFilter from '@/components/common/model-collection/CollectionFilter.vue';
import PitchesTable from '@/components/pitches/Table.vue';
import {Portal} from 'portal-vue';
import {defineComponent} from 'vue';
import store from '@/store/pitches/index';
import teamStore from '@/store/userTeams';

export default defineComponent({
    components: {
        BtnCreateModal,
        CollectionFilter,
        PitchesTable,
        Portal,
    },
    setup() {
        return {
            headers: [
                'name',
                'updatedAt',
                'createdAt',
                'options',
            ],
            pitches: store.pitches,
            teamsIsEmpty: teamStore.teamsIsEmpty,
        };
    },
    computed: {
        activeFilter(): string {
            if (this.pitches.filters?.drafts) {
                return 'draft';
            }

            if (this.pitches.filters?.sharedWith) {
                return 'shared';
            }

            return 'recent';
        },
        noPitches(): boolean {
            const noFilters = this.pitches.filters === undefined
                || !Object.keys(this.pitches.filters).length;

            return this.pitches.isEmpty()
                && !this.pitches.loading
                && noFilters;
        },
    },
});
