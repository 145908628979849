import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchField = _resolveComponent("SearchField")!

  return (_openBlock(), _createBlock(_component_SearchField, {
    "data-test-id": "input-search",
    "model-value": _ctx.filterValue,
    "onUpdate:modelValue": _ctx.updateFilterValue
  }, null, 8, ["model-value", "onUpdate:modelValue"]))
}