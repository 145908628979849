
import {PropType, defineComponent} from 'vue';
import CreateModal from '@/components/pitches/CreateModal.vue';
import {Pitches} from '@/models/Pitch';
import teamStore from '@/store/userTeams';

export default defineComponent({
    components: {
        CreateModal,
    },
    props: {
        pitches: {
            type: Object as PropType<Pitches>,
            default: null,
        },
    },
    setup() {
        const {currentTeam, teamsIsEmpty} = teamStore;

        return {
            currentTeam,
            teamsIsEmpty,
        };
    },
    created() {
        if (this.teamsIsEmpty) {
            teamStore.fetchTeams();
        }
    },
});
