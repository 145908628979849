
import {PropType, defineComponent, ref} from 'vue';
import Collapse from '@/components/common/Collapse.vue';
import PitchMoment from '@/models/PitchMoment';
import {Pitches} from '@/models/Pitch';
import PitchesTable from '@/components/pitches/Table.vue';
import {TableHeader} from '@/components/common/OwTable.vue';
import TimeAgo from '@/components/common/TimeAgo.vue';

export default defineComponent({
    components: {
        Collapse,
        PitchesTable,
        TimeAgo,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        pitchMoment: {
            type: Object as PropType<PitchMoment>,
            required: true,
        },
    },
    setup(props) {
        const headers: TableHeader[] = [
            {
                title: 'team',
                col: 'col-5',
            },
            {
                title: 'pitch',
            },
            {
                title: 'updatedAt',
            },
        ];

        return {
            collapsed: ref<boolean>(true),
            headers,
            options: {
                url: `pitch-moments/${props.pitchMoment.id}/pitches`,
            },
            pitches: ref<Pitches>(new Pitches()),
        };
    },
    created() {
        if (this.open) {
            this.fetchPitches();
        }
    },
    methods: {
        fetchPitches() {
            if (
                this.collapsed
                && this.pitches.isEmpty()
                && !this.pitches.lastPageFetched()
                && !this.pitches.loading
            ) {
                this.pitches.fetch(this.options);
            }

            this.collapsed = !this.collapsed;
        },
    },
});
