import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46826c8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-bs-target"]
const _hoisted_2 = { class: "col-5" }
const _hoisted_3 = { class: "fs-5 title ms-4" }
const _hoisted_4 = {
  key: 0,
  class: "text-muted ms-2"
}
const _hoisted_5 = { class: "col fs-5" }
const _hoisted_6 = { class: "text-muted" }
const _hoisted_7 = { class: "p-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Fa = _resolveComponent("Fa")!
  const _component_TimeAgo = _resolveComponent("TimeAgo")!
  const _component_PitchesTable = _resolveComponent("PitchesTable")!
  const _component_Collapse = _resolveComponent("Collapse")!

  return (_openBlock(), _createBlock(_component_Collapse, {
    name: `pitch-moments-${_ctx.pitchMoment.id}-pitches`,
    open: _ctx.open
  }, {
    "collapse-trigger": _withCtx(({target}) => [
      _createElementVNode("div", {
        class: _normalizeClass(["cursor-pointer p-6 collapse-trigger row align-items-center", {collapsed: !_ctx.open}]),
        "data-bs-target": target,
        "data-bs-toggle": "collapse",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fetchPitches && _ctx.fetchPitches(...args)))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Fa, {
            class: "collapse-trigger-arrow ms-0",
            icon: "chevron-down"
          }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.pitchMoment.name), 1),
          (_ctx.pitchMoment.pitchesCount !== undefined)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (" + _toDisplayString(_ctx.pitchMoment.pitchesCount) + ") ", 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('pitchMoments.index.deadline')), 1),
          _createVNode(_component_TimeAgo, {
            class: "ms-4",
            date: _ctx.pitchMoment.deadlineAt
          }, null, 8, ["date"])
        ])
      ], 10, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_PitchesTable, {
          "disable-infinite-scroll": "",
          headers: _ctx.headers,
          "load-more-title": _ctx.$t('pitchMoments.actions.clickToLoadMoreTeams'),
          options: _ctx.options,
          pitches: _ctx.pitches
        }, null, 8, ["headers", "load-more-title", "options", "pitches"])
      ])
    ]),
    _: 1
  }, 8, ["name", "open"]))
}